import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      //'Content-Type': 'application/json',
     // 'Accept': 'application/json'
    };

    const token = window.localStorage.getItem('SPARKTOKEN');

    if (token && req?.url !== 'https://jarapi.sparkfitness.fit:8001/spark/locker/assign' && req?.url !== 'https://jarapi.sparkfitness.fit:8001/spark/locker/open') {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    // if (token && req?.url !== 'https://device.petalyellow.com:8001/yellow/locker/assign' && req?.url !== 'https://device.petalyellow.com:8001/yellow/locker/open') {
    //   headersConfig['Authorization'] = `Bearer ${token}`;
    // }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
