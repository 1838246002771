import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
  ) { }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    console.log(path);
    console.log(environment.apiURL);
    console.log(params);
    
    
    return this.http.get(`${environment.apiURL}${path}`, { params });
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiURL}${path}`,
      body
    );
  }

  post(path: string, body: Object = {}): Observable<any> {
    console.log(path);
    
    return this.http.post(
      `${environment.apiURL}${path}`,
      body
    );
  }daysChoosed
  
  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.apiURL}${path}`
    );
  }



  downloadFile(path: string, paramsConfig = { fileName: '' }): Observable<HttpEvent<Blob>> {

    if (!paramsConfig.fileName) {
      console.error('mention fileName with your params');
      return;
    }

    let params = new HttpParams();
    for (let key in paramsConfig) {
      params = params.set(key, paramsConfig[key]);
    }

    return this.http.request(new HttpRequest(
      'GET',
      `${environment.apiURL}${path}`,
      // Object.keys(paramsConfig).length > 0 ?  params : null,
      params,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadFileForFees(path: string, paramsConfig = { fileName: '' }): Observable<HttpEvent<Blob>> {

    if (!paramsConfig.fileName) {
      console.error('mention fileName with your params');
      return;
    }

    let params = new HttpParams();
    for (let key in paramsConfig) {
      params = params.set(key, paramsConfig[key]);
    }

    return this.http.request(new HttpRequest(
      'GET',
      `${environment.invoiceURL}${path}`,
      // Object.keys(paramsConfig).length > 0 ?  params : null,
      params,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  

  FilterDownloadFile(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {


    return this.http.request(new HttpRequest(
      'GET',
      `${environment.apiURL}${path}`,
      {
        params: params,
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  pdfDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }

  memberActiveDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }
  
  pdfTrainerDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }
  
  expenseDownloadFile(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {


    return this.http.request(new HttpRequest(
      'GET',
      `${environment.apiURL}${path}`,
      {
        params: params,
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  // expenseDownloadFile(path: string, formData: FormData): Observable<{ formData: FormData, response: Blob }> {
  //   // Convert FormData to query string
  //   let queryString = '';
  //   formData.forEach((value, key) => {
  //     queryString += `${key}=${encodeURIComponent(value.toString())}&`;
  //   });
  //   // Remove the last '&' character
  //   queryString = queryString.slice(0, -1);

  //   // Construct the URL with query parameters
  //   const url = `${environment.apiURL}${path}?${queryString}`;

  //   // Set headers for the request
  //   const headers = new HttpHeaders({
  //     'Accept': 'application/pdf' // Adjust content type as needed
  //   });

  //   // Make a GET request to download the file
  //   return this.http.get(url, {
  //     responseType: 'blob'
  //   }).pipe(
  //     map((response: Blob) => ({ formData, response }))
  //   );
  // }
  uploadFile(file: Blob, path = "upload"): Observable<HttpEvent<void>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true
      }));
  }
  //   getTrackingData(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //     return this.http.get(`${environment.trackingURL}${path}`, { params });
  //   }


  //   ///// device api call /////
  //   deviceget(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //     return this.http.get(`${environment.deviceApiURl}${path}`, { params });
  //   }

  //   deviceput(path: string, body: Object = {}): Observable<any> {
  //     return this.http.put(
  //       `${environment.deviceApiURl}${path}`,
  //       body
  //     );
  //   }

  //   devicepost(path: string, body: Object = {}): Observable<any> {
  //     return this.http.post(
  //       `${environment.deviceApiURl}${path}`,
  //       body
  //     );
  //   }

  //   devicedelete(path: string, body: Object = {}): Observable<any> {
  //     return this.http.delete(
  //       `${environment.deviceApiURl}${path}`, body
  //     );
  //   }
  // DownloadFileDevice(path: string,  params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {


  //     return this.http.request(new HttpRequest(
  //       'GET',
  //       `${environment.deviceApiURl}${path}`,
  //       {
  //         params:params,
  //         reportProgress: true,
  //         responseType: 'blob'
  //       }));
  //   }
  //   // no movement device url 
  //   noMovepost(body: Object = {}): Observable<any> {
  //     return this.http.post(
  //       `${environment.noMovementUrl}`,
  //       body
  //     );
  //   }
  //   // delete dp 
  //   deleteDP(body: Object = {}): Observable<any> {
  //     return this.http.post(
  //       `http://petal-school-development.us-east-1.elasticbeanstalk.com/track/delete/restricted/nomovement`,
  //       body
  //     );
  //   }

  //   getTodayTimeline(params: HttpParams = new HttpParams()): Observable<any> {
  //     return this.http.get(`${environment.todayTimeline}`, { params });
  //   }

  //   subscribeHub(body: Object = {}): Observable<any> {
  //     return this.http.post(`${environment.subscribeHub}`, body );
  //   }

  //   unsubscribeHub(body: Object = {}): Observable<any> {
  //     return this.http.post(`${environment.unsubscribeHub}`,  body );
  //   }
}
