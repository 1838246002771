import { IMenuItem } from "./navigation.model";

export let dietitianMenu: IMenuItem[] = [
  {
    name: "Consultation",
    type: "link",
    tooltip: "Consultation",
    module: ["consultation"],
    state: "dietitian/info/list",
    sub: [
      {
        name: "Consultation",
        type: "link",
        module: ["consultation"],
        state: "dietitian/info/view",
      },
      {
        name: "Consultation",
        type: "link",
        module: ["consultation"],
        state: "dietitian/info/attend",
      },
    ],
  },
];

export let pysioMenu: IMenuItem[] = [
  {
    name: "Consultation",
    type: "link",
    tooltip: "Consultation",
    module: ["consultation"],
    state: "physiotherapist/info/list",
    sub: [
      {
        name: "Consultation",
        type: "link",
        module: ["consultation"],
        state: "physiotherapist/info/view",
      },
      {
        name: "Consultation",
        type: "link",
        module: ["consultation"],
        state: "physiotherapist/info/attend",
      },
    ],
  },
];

export let gymMenu: IMenuItem[] = [
  {
    name: "DASHBOARD",
    type: "link",
    tooltip: "Dashboard",
    module: ["dashboard"],
    state: "dashboard",
  },
  {
    name: "Members",
    type: "link",
    module: ["member","feedback"],
    state: "members",
    sub: [
      { name: "Members", module: ["feedback"],type: "link", state: "feedback" },
    ]
 },
  {
    name: "Employees",
    type: "link",
    module: ["employee","leave_request_trainer_portal"],
    state: "employees",
    sub: [
      { name: "Employees", module: ["leave_request_trainer_portal"],type: "link", state: "leave-request" },
    ]
  },
 
      {
        name: "Consultation",
        type: "dropDown",
        module: ["physiotherapist", "dietition"],
        state: "",
        sub: [
          {
            name: "Physiotherapist",
            module: ["physiotherapist"],
            state: "consultation/physiotherapist-list",
            sub: [
              {
                name: "Physiotherapist",
                module: ["physiotherapist"],
                state: "consultation/Physiotherapist-list",
              },
            ],
          },
          {
            name: "Dietitian",
            module: ["dietition"],
            state: "consultation/dietitian-list",
            sub: [
              {
                name: "Dietitian",
                module: ["dietition"],
                state: "consultation/dietitian-list",
              },
            ],
          },
          {
            name: "Ortho",
            module: ["ortho"],
            state: "consultation/ortho-list",
            sub: [
              {
                name: "Ortho",
                module: ["ortho"],
                state: "consultation/ortho-list",
              },
            ],
          },
          {
            name: "Neuro",
            module: ["neuro"],
            state: "consulation/neuro-list",
            sub: [
              {
                name: "Neuro",
                module: ["neuro"],
                state: "consulation/neuro-list",
              },
            ],
          },
        ],
      },
      {
        name: "Workout",
        type: "link",
        tooltip: "Workout",
        module: ["workout"],
        state: "workout",
        sub: [
          { name: "Workout", module: ["workout"], state: "workout/create" },
          { name: "Workout", module: ["workout"], state: "workout/edit" },
          { name: "Workout", module: ["workout"], state: "workout/view" },
        ],
      },
      {
        name: "Meal Plan",
        type: "link",
        tooltip: "Meal Plan",
        module: ["meal_plan"],
        state: "meal-plan",
        sub: [
          { name: "Meal Plan", module: ["meal_plan"], state: "diet-plan/create" },
          { name: "Meal Plan", module: ["meal_plan"], state: "diet-plan/edit" },
          { name: "Meal Plan", module: ["meal_plan"], state: "diet-plan/view" },
        ],
      },
  // {
  //   name: "Lockers",
  //   type: "link",
  //   tooltip: "Locker",
  //   module: ["locker"],
  //   state: "locker",
  //   sub: [
  //     {
  //       name: "Locker",
  //       type: "link",
  //       module: ["locker"],
  //       state: "locker/assign",
  //     },
  //     {
  //       name: "Locker",
  //       type: "link",
  //       module: ["locker"],
  //       state: "locker/available",
  //     },
  //   ],
  // },
  {
    name: "Fees",
    type: "link",
    tooltip: "Fees",
    module: ["fees"],
    state: "fees",
    sub: [
      { name: "Fees", module: ["fees"], state: "fees/fees-payment" },
      { name: "Fees", module: ["fees"], state: "fees/member-search" },
      { name: "Fees", module: ["fees"], state: "fees/paynow" },
      { name: "Fees", module: ["fees"], state: "fees/billview" },
    ],
  },
  {
    name: "Accounts",
    type: "dropDown",
    module: ["group","ledger", "expenses", "sundry_parties", "opening_balance", "closing_balance"],
    state: "",
    sub: [
      { name: "Group", module: ["group"], state: "accounts/group" },
      { name: "Ledger", module: ["ledger"], state: "accounts/ledger" },
      { name: "Expenses", module: ["expenses"], state: "accounts/expenses" },
      {
        name: "Sundry Parties",
        module: ["sundry_parties"],
        state: "accounts/sundry-parties",
      },
      {
        name: "Opening Balance",
        module: ["opening_balance"],
        state: "accounts/opening-balance",
      },
      {
        name: "Closing Balance",
        module: ["closing_balance"],
        state: "accounts/closing-balance",
      },
    ],
  },

 

  {
    name: "Reports",
    type: "dropDown",
    module: ["body_measurement_report", "weight_measurement_report", "upcoming_renewals_trainer_membership",'complaint_report', "active_member_report", 'payment_transaction_report', 'upcoming_renewals','employee_report', "balance_sheet_report","employee_attendance","group_balance_sheet"],
    state: "",
    sub: [
      {
        name: "Employees",
        type: "dropDown",
        module: ["employee_report", "employee_attendance"],
        state: "",
        sub: [
          {
            name: "Overall",
            module: ["employee_report"],
            state: "reports/overall-employee",
          },
          {
            name: "Attendance",
            module: ["employee_attendance"],
            state: "reports/employee-attendance",
          },
          // {
          //   name: "Weight Measurement",
          //   module: ["employee-weight"],
          //   state: "reports/employee-weight",
          // }
        ],
      },
      {
        name: "Members",
        type: "dropDown",
        module: ["body_measurement_report", "upcoming_renewals", "weight_measurement_report",'member_individual_report','member_admission_report'],
        state: "",
        sub: [
          {
            name: "Attendance",
            module: ["member_attendance"],
            state: "reports/member-attendance",
          },
          {
            name: "Member Admission",
            module: ["member_admission_report"],
            state: "reports/member/adms-date and trainer",
          },
          {
            name: "Individual",
            module: ["member_individual_report"],
            state: "reports/individual-member/history",
          },

          {
            name: "Body Measurement",
            module: ["body_measurement_report"],
            state: "reports/body-measurement",
          },
          {
            name: "Weight Measurement",
            module: ["weight_measurement_report"],
            state: "reports/weight-measurement",
          },
          {
            name: "Upcoming Renewals",
            module: ["upcoming_renewals"],
            state: "reports/upcoming",
          },
        ],
      },

      {
        name: "Feedback",
        module: ["complaint_report"],
        state: "reports/feedback",
      },

      {
        name: "Payment Transaction",
        module: ["payment_transaction_report"],
        state: "reports/payment-transaction",
      },
      {
        name: "Balance Sheet",
        module: ["balance_sheet_report","group_balance_sheet"],
        type: "link",
        state: "reports/balance-sheet",
        sub: [
          { name: "Group Balance Sheet", module: ["group_balance_sheet"],type: "link", state: "group-balance-sheet" },
        ]
      },
      {
        name: "Expenses Report",
        module: ["expenses_report"],
        state: "reports/expenses_report",
      },
      {
        name: "Active Member Report",
        module: ["active_member_report"],
        state: "reports/active-member-report"
      },
      {
        name: "Expiring Trainer Memberships",
        module: ["upcoming_renewals_trainer_membership"],
        state: "reports/upcoming-trainer-membership",
      },
    ],
  },
  {
    name: "Configuration",
    type: "dropDown",
    tooltip: "Configuration",
    module: [
      "user",
      "role",
      "role_permission",
      "employee_type",
      "employee_attendance_setting",
      "member_attendance_setting",
      "threshold_setting",
      // "trainer_type",
      "food",
      "workout_category",
      "membership",
      "equipment",
      "exercise",
      "strech",
      "warm_up",
      // "locker_config",
      "consultation_fees",
      // "hub_settings",
      "mealplan_name",
      "trainer_attendance",
      'trainer_membership',
      "heart_rate",
      "weight_receiver",
      "heartrate_receiver"
    ],
    state: "",
    sub: [
      {
        name: "General Settings",
        type: "dropDown",
        state: "",
        module: [
          'trainer_attendance',
          // 'threshold_setting',
          'employee_type',
          // 'trainer-type',
          'hub_settings',
          'weight_receiver',
          // 'heartrate_receiver',
          'slot',
          'consultation_fees',
          // 'heart_rate',
        ],
        sub: [
          {
            name: "Attendance",
            type: "link",
            module: ["trainer_attendance"],
            state: "employee-attendance-setting",
            // sub: [
            //   {
            //     name: "Employee",
            //     module: ["trainer_attendance"],
            //     state: "employee-attendance-setting",
            //   },
            // ],
          },
          // {
          //   name: "Threshold",
          //   type: "link",
          //   module: ["threshold_setting"],
          //   state: "threshold-settings",
          //   sub: [
          //     {
          //       name: "Threshold",
          //       module: ["threshold_setting"],
          //       state: "threshold-settings/create",
          //     },
          //     {
          //       name: "Threshold",
          //       module: ["threshold_setting"],
          //       state: "threshold-settings/edit",
          //     },
          //   ],
          // },

          // {
          //   name: "Gym Timings",
          //   state: "gym-timings",
          // },
          // {
          //   name: "Heart Rate",
          //   type: "link",
          //   state: "heartrate",
          //   module: ["heart_rate"],
          // },
         
          {
            name: "Employee Type",
            type: "link",
            module: ["employee_type"],
            state: "employee-type",
            sub: [
              {
                name: "Employee Type",
                module: ["employee-type"],
                state: "employee-type/create",
              },
              {
                name: "Employee Type",
                module: ["employee-type"],
                state: "employee-type/edit",
              },
              {
                name: "Employee Type",
                module: ["employee-type"],
                state: "employee-type/view",
              },
            ],
          },
          // {
          //   name: "Trainer Type",
          //   module: ["trainer-type"],
          //   state: "trainer-type",
          // },
        
          {
            name: "Consultation Fees",
            module: ["consultation_fees"],
            state: "consultation-fees-update",
          },
          // {
          //   name: "Hub",
          //   module: ["hub_settings"],
          //   state: "hub_settings",
          // },
          // {
          //   name: "Weight Receiver",
          //   module: ["weight_receiver"],
          //   state: "weight-receiver",
          // },
          // {
          //   name: "Heart Rate Receiver",
          //   module: ["heartrate_receiver"],
          //   state: "heartrate-receiver",
          // },
          // {
          //   name: "Hub",
          //   type: "dropDown",
          //   state: "",
          //   sub: [
          //     {
          //       name: "Hub",
          //       module: ["hub_settings"],
          //       state: "hub_settings",
          //     },
          //     {
          //       name: "Weight Receiver",
          //       module: ["weight_receiver"],
          //       state: "weight-receiver",
          //     },
          //     {
          //       name: "Heart Rate Receiver",
          //       module: ["heartrate_receiver"],
          //       state: "heartrate-receiver",
          //     },
          //   ]
          // },
         
          {
            name: "Slot Settings",
            module: ["slot"],
            state: "slot-settings",
          },
          // {
          //   name: "Shift Settings",
          //   module: ["shift"],
          //   state: "shift-settings",
          // },
          // {
          //   name: "Locker Settings",
          //   module: ["locker"],
          //   state: "locker-setting",
          // },
        ],
      },
      {
        name: "Meal",
        type: "dropDown",
        module: ["food", "mealplan_name"],
        state: "",
        sub: [
          {
            name: "Meal Food",
            type: "link",
            module: ["food"],
            state: "meal-food",
            sub: [
              {
                name: "Meal Food",
                type: "link",
                module: ["food"],
                state: "meal-food/edit",
              },
              {
                name: "Meal Food",
                type: "link",
                module: ["food"],
                state: "meal-food/create",
              },
              {
                name: "Meal Food",
                type: "link",
                module: ["food"],
                state: "meal-food/view",
              },
            ],
          },
          {
            name: "Meal Plan Name",
            module: ["mealplan_name"],
            state: "mealplan-name",
          },
        ],
      },
      {
        name: "Workout Plan Name",
        module: ["workout_category"],
        state: "workoutplan-name",
      },
      {
        name: "Membership",
        module: ["membership"],
        state: "membership",
        sub: [
          {
            name: "Membership",
            module: ["membership"],
            state: "membership/create",
          },
          {
            name: "Membership",
            module: ["membership"],
            state: "membership/edit",
          },
        ],
      },

      {
        name: "Trainer Membership",
        module: ["trainer_membership"],
        state: "trainer-membership",
        sub: [
          {
            name: "Trainer Membership",
            module: ["trainer_membership"],
            state: "trainer-membership/create",
          },
          {
            name: "Trainer Membership",
            module: ["trainer_membership"],
            state: "trainer-membership/edit",
          },
        ],
      },
      
      { name: "Equipment", module: ["equipment"], state: "equipment" },

      {
        name: "Exercise",
        module: ["exercise"],
        state: "exercise",
        sub: [
          { name: "Exercise", module: ["exercise"], state: "exercise/create" },
          { name: "Exercise", module: ["exercise"], state: "exercise/edit" },
          { name: "Exercise", module: ["exercise"], state: "exercise/view" },
        ],
      },
      {
        name: "Stretches",
        module: ["strech"],
        state: "stretch",
        sub: [
          { name: "Stretches", module: ["stretch"], state: "stretch/create" },
          { name: "Stretches", module: ["stretch"], state: "stretch/edit" },
          { name: "Stretches", module: ["stretch"], state: "stretch/view" },
        ],
      },
      {
        name: "Warm Up",
        module: ["warm_up"],
        state: "warmup",
        sub: [
          { name: "Warm Up", module: ["warm_up"], state: "warm-up/create" },
          { name: "Warm Up", module: ["warm_up"], state: "warm-up/edit" },
          { name: "Warm Up", module: ["warm_up"], state: "warm-up/view" },
        ],
      },
      // { name: "Locker", module: ["locker_config"], state: "locker-update" },
      {
        name: "Administration",
        type: "dropDown",
        module: ["user", "role", "role_permission"],
        state: "",
        sub: [
          { name: "Roles", module: ["role"], state: "role" },
          { name: "Users", module: ["user"], state: "users" },
          {
            name: "Roles & Permissions",
            module: ["role_permission"],
            state: "role-permission",
          },
        ],
      },
      { name: "Finger Print", module: ["finger-print"], state: "" },
    ],
  },
  {
    name: "Enquiry",
    type: "link",
    tooltip: "Enquiry",
    module: ["Enquiry"],
    state: "enquiry",
  }
];

export let trainerMenu: IMenuItem[] = [


  {
    name: "Members",
    type: "link",
    tooltip: "Members",
    module: ["member"],
    state: "members",
    sub: [
      {
        name: "Member",
        type: "link",
        module: ["member"],
        state: "members/view",
      },
      {
        name: "Member",
        type: "link",
        module: ["member"],
        state: "members/edit",
      },
      {
        name: "Member",
        type: "link",
        module: ["member"],
        state: "members/create",
      },
    ],
  },
  {
    name: "Consultation",
    type: "dropDown",
    module: ["physiotherapist", "dietition"],
    state: "",
    sub: [
      {
        name: "Physiotherapist",
        module: ["physiotherapist"],
        state: "consultation/physiotherapist-list",
        sub: [
          {
            name: "Physiotherapist",
            module: ["physiotherapist"],
            state: "consultation/Physiotherapist-list",
          },
        ],
      },
      {
        name: "Dietitian",
        module: ["dietition"],
        state: "consultation/dietitian-list",
        sub: [
          {
            name: "Dietitian",
            module: ["dietition"],
            state: "consultation/dietitian-list",
          },
        ],
      },
    ],
  },
  // {
  //   name: "Leave Request",
  //   module: ["leave_request_trainer_portal"],
  //   state: "leave-request",
  // },
  {
    name: "Leave Request",
    type: "link",
    module: ["leave_request_trainer_portal"],
    state: "leave-request",
    sub: [],
  },
  {
    name: "Workout",
    type: "link",
    tooltip: "Workout",
    module: ["workout"],
    state: "workout",
    sub: [
      { name: "Workout", module: ["workout"], state: "workout/create" },
      { name: "Workout", module: ["workout"], state: "workout/edit" },
      { name: "Workout", module: ["workout"], state: "workout/view" },
    ],
  },

  {
    name: "Meal Plan",
    type: "link",
    tooltip: "Meal Plan",
    module: ["meal_plan"],
    state: "meal-plan",
    sub: [
      { name: "Meal Plan", module: ["meal_plan"], state: "diet-plan/create" },
      { name: "Meal Plan", module: ["meal_plan"], state: "diet-plan/edit" },
      { name: "Meal Plan", module: ["meal_plan"], state: "diet-plan/view" },
    ],
  },

  {
    name: "Report",
    type: "dropDown",
    module: ["body_measurement_report", "weight_measurement_report"],
    state: "",
    sub: [
      {
        name: "Weight Measurement",
        module: ["weight_measurement_report"],
        state: "reports/weight-measurement",
      },
      {
        name: "Body Measurement",
        module: ["body_measurement_report"],
        state: "reports/body-measurement",
      },
    ],
  },
  {
    name: "Configuration",
    type: "dropDown",
    tooltip: "Configuration",
    module: [
      "food",
      "workout_category",
      "membership",
      "equipment",
      "exercise",
      "strech",
      "warm_up",
      "mealplan_name",
    ],
    state: "",
    sub: [
      {
        name: "Meal",
        type: "dropDown",
        module: ["food", "mealplan_name"],
        state: "",
        sub: [
          {
            name: "Meal Food",
            type: "link",
            module: ["food"],
            state: "meal-food",
            sub: [
              {
                name: "Meal Food",
                type: "link",
                module: ["food"],
                state: "meal-food/edit",
              },
              {
                name: "Meal Food",
                type: "link",
                module: ["food"],
                state: "meal-food/create",
              },
              {
                name: "Meal Food",
                type: "link",
                module: ["food"],
                state: "meal-food/view",
              },
            ],
          },
          {
            name: "Meal Plan Name",
            module: ["mealplan_name"],
            state: "mealplan-name",
          },
        ],
      },
      {
        name: "Workout Plan Name",
        module: ["workout_category"],
        state: "workoutplan-name",
      },
      {
        name: "Membership",
        module: ["membership"],
        state: "membership",
        sub: [
          {
            name: "Membership",
            module: ["membership"],
            state: "membership/create",
          },
          {
            name: "Membership",
            module: ["membership"],
            state: "membership/edit",
          },
        ],
      },
      { name: "Equipment", module: ["equipment"], state: "equipment" },
      {
        name: "Exercise",
        module: ["exercise"],
        state: "exercise",
        sub: [
          { name: "Exercise", module: ["exercise"], state: "exercise/create" },
          { name: "Exercise", module: ["exercise"], state: "exercise/edit" },
          { name: "Exercise", module: ["exercise"], state: "exercise/view" },
        ],
      },
      {
        name: "Stretches",
        module: ["strech"],
        state: "stretch",
        sub: [
          { name: "Stretches", module: ["stretch"], state: "stretch/create" },
          { name: "Stretches", module: ["stretch"], state: "stretch/edit" },
          { name: "Stretches", module: ["stretch"], state: "stretch/view" },
        ],
      },
      {
        name: "Warm Up",
        module: ["warm_up"],
        state: "warmup",
        sub: [
          { name: "Warm Up", module: ["warm_up"], state: "warm-up/create" },
          { name: "Warm Up", module: ["warm_up"], state: "warm-up/edit" },
          { name: "Warm Up", module: ["warm_up"], state: "warm-up/view" },
        ],
      },
    ],
  },
];
