<div class="header-topnav mat-elevation-z2">
    <div class="container">

        <div class="topnav">
            <!-- App Logo -->
            <div class="topbar-branding" >
                <img src="assets/images/hyrox_logo_dashboard.png" alt="" class="app-logo" (click)="redirection()"  />
            </div>
            <ul class="menu" *ngIf="!layoutConf.isMobile">
                <li *ngFor="let item of menuItems; let i = index;">
                    <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
                        <a matRipple routerLink="/{{item.state}}" *ngIf="item.type === 'link'">
                            {{item.name | translate}}
                        </a>
                        <div *ngFor="let s of item.sub" style="display: none;">
                            {{s}}
                            <a matRipple *ngIf="item.name == s.name" routerLink="/{{s.state}}"> {{s.name }}</a>
                        </div>
                        <div *ngIf="item.type === 'dropDown'">
                            <label matRipple for="drop-{{i}}" class="toggle">{{item.name | translate}}
                            </label>
                            <a matRipple>{{item.name | translate}}
                            </a>
                            <input type="checkbox" id="drop-{{i}}" />
                            <ul class="submenu">
                                <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                                    <a class="txt-blue" matRipple routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                                        *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.name != 'Finger Print'">
                                        {{itemLvL2.name | translate}}
                                    </a>
                                    <!-- href="http://54.234.101.61:8080/iclock/lang/en/modules/main/dashboard/dashboard.jsp?UserId=101&PageId=1&AutoLogin=0" -->
                                    <a class="txt-blue" *ngIf="itemLvL2.name == 'Finger Print'" matRipple href="http://89.116.33.38:8090/"
                                        target="_blank">
                                        {{itemLvL2.name | translate}}
                                    </a>
                                    <div *ngFor="let hs of itemLvL2.sub" style="display: none;">
                                        <a *ngIf="itemLvL2.name == hs.name" matRipple
                                            routerLink="/{{hs.state}}">{{hs.name}}</a>
                                    </div>
                                    <div *ngIf="itemLvL2.type === 'dropDown'">
                                        <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name |
                                            translate}}</label>
                                        <a matRipple>{{itemLvL2.name | translate}}
                                        </a>
                                        <input type="checkbox" id="drop-{{i}}{{j}}" />
                                        <!-- Level 3 -->
                                        <ul>
                                            <li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                                                <a matRipple *ngIf="itemLvL3.type !== 'dropDown' &&  itemLvL3.name != 'Finger Print'"
                                                    routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                                                    {{itemLvL3.name | translate}}
                                                </a>
                                                <a class="txt-blue" *ngIf="itemLvL3.name == 'Finger Print'" matRipple href="http://89.116.33.38:8090/"
                                                target="_blank">
                                                {{itemLvL3.name | translate}}
                                            </a>
                                                <div *ngIf="itemLvL3.type === 'dropDown'">
                                                    <label matRipple for="drop-{{j}}{{k}}"
                                                        class="toggle">{{itemLvL3.name | translate}}</label>
                                                    <a matRipple> {{itemLvL3.name | translate}}
                                                    </a>
                                                    
                                    
                                                    <input type="checkbox" id="drop-{{j}}{{k}}" />
                                                    <!-- Level 3 -->
                                                    <ul>
                                                        <li *ngFor="let itemLvL4 of itemLvL3.sub"
                                                            routerLinkActive="open">
                                                            <a matRipple *ngIf="itemLvL4.type !== 'dropDown' && itemLvL4.name != 'Finger Print'"
                                                                routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL3.state ? '/'+itemLvL3.state : ''}}/{{itemLvL4.state}}">
                                                                {{itemLvL4.name | translate}}
                                                            </a>
                                                            <a class="txt-blue" *ngIf="itemLvL4.name == 'Finger Print'" matRipple href="http://89.116.33.38:8090/"
                                                            target="_blank">
                                                            {{itemLvL4.name | translate}}
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
            <span fxFlex></span>
            <!-- End Navigation -->

            <span class="text-white pr-8" *ngIf="portalType === checkType.GYM">{{
                name
                }}</span>
            <span class="text-white pr-8" *ngIf="portalType === checkType.DIETITIAN">{{ userName }}</span>
            <span class="text-white pr-8" *ngIf="portalType === checkType.PHYSIOTHERAPIST">{{ userName }}</span>
            <!-- Top left user menu -->
            <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right mr-1 img-button">
                <img src="{{details?.employee_photo ? details?.employee_photo : details?.photo  ? details?.photo : 'assets/images/avartar.jpg'}}"
                    alt="Profile" />
            </button>
            <mat-menu #accountMenu="matMenu">
                <ng-container *ngIf="portalType === checkType.GYM">
                    <button mat-menu-item routerLink="/change-password">
                        <mat-icon>lock</mat-icon>
                        <span>Change Password</span>
                    </button>
                    <button mat-menu-item (click)="logout(portalType)">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sign out</span>
                    </button>
                </ng-container>
                <ng-container *ngIf="portalType === checkType.PHYSIOTHERAPIST">
                    <button mat-menu-item [routerLink]="['/physiotherapist/my-account/change-password']">
                        <mat-icon>lock</mat-icon>
                        <span>Change Password</span>
                    </button>
                    <button mat-menu-item (click)="logout(portalType)">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sign out</span>
                    </button>
                </ng-container>
                <ng-container *ngIf="portalType === checkType.DIETITIAN">
                    <button mat-menu-item [routerLink]="['/dietitian/my-account/change-password']">
                        <mat-icon>lock</mat-icon>
                        <span>Change Password</span>
                    </button>
                    <button mat-menu-item (click)="logout(portalType)" [routerLink]="['/dietitian/sessions/signin']">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sign out</span>
                    </button>
                </ng-container>
            </mat-menu>
            <!-- Notification toggle button -->

            <!-- Gym Portal -->
            <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()"
                *ngIf="portalType === checkType.GYM && userType == 'admin' || user == 2" [style.overflow]="'visible'"
                class="topbar-button-right">
                <mat-icon class="white-color">notifications</mat-icon>
                <span class="notification-number mat-bg-success">{{ queueCount }}</span>
            </button>
            <!-- Dietitian Portal -->

            <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()"
                *ngIf="portalType === checkType.DIETITIAN" [style.overflow]="'visible'" class="topbar-button-right">
                <mat-icon class="white-color">notifications</mat-icon>
                <span class="notification-number mat-bg-success">{{ queueCount }}</span>
            </button>

            <!-- Physio Portal -->
            <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()"
                *ngIf="portalType === checkType.PHYSIOTHERAPIST" [style.overflow]="'visible'"
                class="topbar-button-right">
                <mat-icon class="white-color">notifications</mat-icon>
                <span class="notification-number mat-bg-success">{{ queueCount }}</span>
            </button>
            <!-- Mobile screen menu toggle -->
            <button mat-icon-button class="mr-1" (click)="toggleSidenav()" *ngIf="layoutConf.isMobile">
                <mat-icon class="text-white">menu</mat-icon>
            </button>
        </div>
    </div>
</div>