import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import { ApiService } from "app/core/services/api.service";
import { decodedToken } from "app/core/helpers/token.helper";
import { catchError } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppLoaderService } from "app/core/services/app-loader.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  token: any;

  constructor(private apiService: ApiService, private snackBar: MatSnackBar, private loader: AppLoaderService,) {
    this.token = decodedToken();
  }

  getList(paginationDTO): Observable<any> {
    let params = new HttpParams();
    for (let key in paginationDTO) {
      params = params.set(key, paginationDTO[key]);
    }
    if (this.token?.acl_role_id != 2) {
      return this.apiService.get(`notification`, params).pipe(
        catchError((error) => {
          let errorMessage = 'An unexpected error occurred. Please try again later.';
          if (error.status === 500) {
            errorMessage = 'Internal Server Error. Something went wrong.';
          } else if (error.status === 404) {
            errorMessage = 'Resource not found. Please check your request.';
          } 
          this.snackBar.open(errorMessage, 'Close', {
            duration: 5000,
          });
          this.loader.close();
          return of(null);
        })
      ); 
    } else if(this.token?.acl_role_id == 2) {
      return this.apiService.get('tp_notification', params).pipe(
        catchError((error) => {
          let errorMessage = 'An unexpected error occurred. Please try again later.';
          
          if (error.status === 500) {
            errorMessage = 'Internal Server Error. Something went wrong.';
          } else if (error.status === 404) {
            errorMessage = 'Resource not found. Please check your request.';
          }
          this.snackBar.open(errorMessage, 'Close', {
            duration: 5000,
          });
          this.loader.close();
          return of(null);
        })
      );
    }
    
  }

  // getList(paginationDTO) {
  //   let params = new HttpParams();
  //   for (let key in paginationDTO) {
  //     params = params.set(key, paginationDTO[key]);
  //   }
  //   if(this.token?.acl_role_id != 2){
  //     return this.apiService.get('notification', {params)
  //   }
  //   else if(this.token?.acl_role_id == 2){
  //     return this.apiService.get('tp_notification', params)
  //   }

  // }

  setReadStatus(key): Observable<any> {
    return this.apiService.post('readNotification', key);
  }
  // // get one list
  // getOne(id): Observable<any> {
  //   return this.apiService
  //     .get(`notice/${id}`)
  //     .pipe(map((response) => this.adapter.adapt(response)));
  // }

  // // list with filtering pagination
  // getList(paginationDTO): Observable<INoticeBoardAPI> {
  //   let params = new HttpParams();
  //   for (let key in paginationDTO) {
  //     params = params.set(key, paginationDTO[key]);
  //   }
  //   return this.apiService
  //     .get(`notice`, params)
  //     .pipe(map((response) => this.adapter.adapt(response)));
  // }
}
