import { Component, OnInit, Input, } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { ConfigService } from 'app/core/services/config.service';
import { Subscription, Observable, merge, of, pipe, from } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { AppLoaderService } from 'app/core/services/app-loader.service';
import { GenerateRouteService } from 'app/core/services/generate-route.service';
import { ToastService } from 'app/core/services/toast.service';
import { decodedToken, getToken } from 'app/core/helpers/token.helper';
import { get } from 'lodash';
import { PORTALTYPE } from "../../../core/helpers/enum.helper";
import { MessagingService } from 'app/core/services/messaging.service';
import { NotificationsService } from 'app/modules/gym/notifications/service/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})

export class NotificationsComponent implements OnInit {
  @Input() notificPanel;
  dateNumber = Date.now();
  notifications = [];
  count: number = 0;
  portalType: any;
  notifies = [];




  constructor(private router: Router,
    private notificationService: NotificationsService,
    private loader: AppLoaderService,
    private generateRouteService: GenerateRouteService,
    private messagingService: MessagingService,
    private toastr: ToastService,

  ) {
    this.getNotificationList(); 
    this.getCurrentNotification();
    this.removeNotificationBasedOnId();
    this.reloadNotify();
  }







  reloadNotify() {
    this.messagingService.reloadNotificationList.subscribe(res => {
      if (res) {
        this.getNotificationList();
      }
    })
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
  }


  getCurrentNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res) {
        this.notifications.unshift(res);
      }
    })
  }

  getNotificationList() {
    let p;
    this.notificationService.getList(p)
      .subscribe(res => {
        console.log(res, "Notification")
        if (res.keyword == 'success') {
          this.notifications = res.data.filter(el => el.msg_read == 0);
          this.count = this.notifications.length;
          this.generateRouteService.setQueueCount(this.count, true);
        } else {
          this.notifications = [];
        }
      });
  }

  clicked(index, row) {
    let obj = {
      id: JSON.stringify([row.notification_id]),
      // db_type: JSON.stringify([row.db_type])
    }
    this.notificationService.setReadStatus(obj)
      .subscribe(res => {
      })
    this.notifications.splice(index, 1);
    this.messagingService.appendQueueCount(0);
    if (this.portalType === PORTALTYPE.GYM) {
      this.generateRouteService.navigateRouteForGym(row.portal, row.page, row.data);
    } else if (this.portalType === PORTALTYPE.DIETITIAN) {
      this.generateRouteService.navigateRouteForDietitian(row.portal, row.page, row.data);
    } else if (this.portalType === PORTALTYPE.PHYSIOTHERAPIST) {
      this.generateRouteService.navigateRouteForPhysio(row.portal, row.page, row.data);
    }
  }


  removeNotificationBasedOnId() {
    this.messagingService.removeMessage.subscribe(res => {
      if (res) {
        let index = this.notifications.findIndex(el => el.notification_id == res.notification_id);
        this.notifications.splice(index, 1);
      }
    })
  }


  viewMore() {
    if (this.portalType === PORTALTYPE.GYM) {
      this.router.navigate(['/notifications/list']);
    } else if (this.portalType === PORTALTYPE.DIETITIAN) {
      this.router.navigate(['/dietitian/notifications/list']);
    } else if (this.portalType === PORTALTYPE.PHYSIOTHERAPIST) {
      this.router.navigate(['/physiotherapist/notifications/list']);
    }
  }

  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }




}
