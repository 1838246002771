import { Pipe, PipeTransform } from "@angular/core";
import {
    DomSanitizer,
    SafeHtml,
    SafeStyle,
    SafeScript,
    SafeUrl,
    SafeResourceUrl,
} from "@angular/platform-browser";
import { format, isValid } from "date-fns";
import * as moment from "moment";
import { getDateFnsFormat, getDateTimeFnsFormat, getTimeFnsFormat } from 'app/core/helpers/app-config.helper';
import { NativeDateAdapter } from "@angular/material/core";

@Pipe({ name: 'serverDate' })
export class AppServerDatePipe implements PipeTransform {
    transform(value: string): string {
        console.log(format(new Date(value), 'MM-DD-YYYY'), value)
        value = value ? value : '';
        return value.length > 0 ? format(new Date(value), 'MM-DD-YYYY') : '';
    }
}
@Pipe({ name: "MonthDate"})
export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}
@Pipe({
    name: "safeHtml",
})
export class SafePipe {
    constructor(protected sanitizer: DomSanitizer) { }
    transform(htmlString: string): any {
        return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    }
}


@Pipe({ name: 'displayDate' })
export class AppDisplayDatePipe implements PipeTransform {
    transform(value: string): string {
        var dateValue = new Date(value);
        return isValid(dateValue) ? format(dateValue, getDateFnsFormat()) : '';
    }
}

@Pipe({ name: 'displayTime' })
export class AppDisplayTimePipe implements PipeTransform {
    transform(value: string): string {
        let formattedDate = moment().format('YYYY-MM-DD');
        let formattedTime = value;
        let format = getTimeFnsFormat();
        let dispTime = moment(`${formattedDate} ${formattedTime}`).format(format);
        return value ? dispTime : '';
    }
}