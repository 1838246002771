
import { Injectable } from '@angular/core';
import { IChildItem, IMenuItem } from "../models/navigation.model";
import { gymMenu, dietitianMenu, pysioMenu, trainerMenu } from "../models/menu.model";
import { PORTALTYPE } from "../helpers/enum.helper";
import { decodedToken } from '../helpers/token.helper';
import { get } from "lodash";

@Injectable({
    providedIn: 'root'
})

export class PermissionService {

    menuSortList: IMenuItem[] = [];
    permissions: Object;
    roleId: number = 0;
    portalType: string = '';

    constructor() {

    }

    hasPermission(moduleList: Array<any>, actionList?: Array<any>): boolean {
        this.autoInit();
      
        if (this.roleId <= 0) {
            return false;
        }

        if (this.roleId == 1) {
            return true;
        }

        if (!this.permissions) {
            return false;
        }

        if (!actionList) {
            return this.isModuleHasAction(this.permissions, moduleList);
        }

        if (actionList?.length > 0) {
            return this.isActionFound(this.permissions, moduleList, actionList);
        }

        return false;
    }

    isModuleHasAction(permList: Object, moduleList: Array<any>): boolean {
        let admits = moduleList.filter(value => {
            if (permList.hasOwnProperty(value)) {
                return this.getValueByKey(permList, value).length > 0;
            }
        });
        return admits.length > 0;
    }

    isActionFound(permList: Object, moduleList: Array<any>, searchList: Array<any>): boolean {
        let admits = moduleList.filter(value => {
            if (permList.hasOwnProperty(value)) {
                let moduleActions = this.getValueByKey(permList, value);
                let permittedAction = moduleActions.filter(maction => {
                    return searchList.indexOf(maction) !== -1;
                });
                return permittedAction.length > 0;
            }
        });
        return admits.length > 0;
    }

    init(roleId: number, portalType: string, permissions: Object) {
        this.roleId = roleId;
        this.portalType = portalType;
        this.permissions = Object.keys(permissions).length > 0 ? permissions : {};
    }

    autoInit() {
        const token = decodedToken();
        this.portalType = get(token, 'type') ?? '';
        this.roleId = get(token, 'acl_role_id') ?? '';
        this.permissions = get(token, 'permission') ?? {};
    }


    // get authMenu(): IMenuItem[] {
    //     if ((this.roleId == 1 && this.portalType == PORTALTYPE.GYM) || this.portalType == PORTALTYPE.DIETITIAN || this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
    //         return this.menu;
    //     }
    //     let menu: IMenuItem[] = [];
    //     this.menuSortList = this.menu;
    //     menu = this.menuSortList.filter(data => {
    //         return (data.module?.length > 0 && this.hasPermission(data.module));
    //     });
    //     return menu.map(x => {
    //         if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
    //         return x;
    //     });
    // }

    
    get authMenu(): IMenuItem[] {
        if ((this.roleId == 1 && this.portalType == PORTALTYPE.GYM) || this.roleId == 2  || this.portalType == PORTALTYPE.DIETITIAN || this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
            return this.menu;
        }
        let menu: IMenuItem[] = [];
        this.menuSortList = this.menu;

        menu = this.menuSortList.filter(data => {
            return (data.module?.length > 0 && this.hasPermission(data.module));
        });
        // console.log(this.menu, "shorlist menu");
        return menu.map(x => {
            if (x?.sub) { x.sub = this.getAuthSubmMenu(x.sub); }
            return x;
        });
    }

    getAuthSubmMenu(ichild: IChildItem[]): IChildItem[] {
        let childMenu: IChildItem[] = [];
        childMenu = ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
        return childMenu.map((x) => {
            if (x?.sub) {
                x.sub = this.getAuthChildSubMenu(x.sub);
            }
            return x;
        });
    }

    getAuthChildSubMenu(ichild: IChildItem[]): IChildItem[] {
        return ichild.filter((data) => {
            return data.module?.length > 0 && this.hasPermission(data.module);
        });
    }

    get menu(): IMenuItem[] {
        let menuList: IMenuItem[] = [];
        if (this.portalType == PORTALTYPE.GYM) {
            if(this.roleId == 2){
                menuList = trainerMenu;
            }else {
                menuList = gymMenu; 
            }
        } else if (this.portalType == PORTALTYPE.DIETITIAN) {
            menuList = dietitianMenu;
        } else if (this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
            menuList = pysioMenu;
        } else {
            menuList = gymMenu;
        }
        return menuList;
    }
    getValueByKey(object: Object, searchKey: string): Array<any> {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                if (key === searchKey) {
                    return object[key] as Array<any>;
                }
            }
        }
        return [];
    }

}







