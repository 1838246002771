// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// "outputPath": "sparkfitness.fit/dist",
export const environment = {
  production: false,
  apiURL: "https://api.hyroxfitnessclub.com/api/V1/{endPoint}/",
  //  apiURL1: "http://192.168.1.7:8001/api/V1/GP/membership_active_reportPdf/",
  // apiURL: "https://api.sparkfitness.fit/api/V1/{endPoint}/",
  // apiURL: "https://api.phoenixcrosfit.com/api/V1/{endPoint}/",
  // apiURL: "https://api.petalyellow.com/api/V1/{endPoint}/",
  invoiceURL: "https://testapi.sparkfitness.fit/",

  // lockerbaseURL: "http://54.81.106.132:8001/",

  lockerbaseURL: "https://jarapi.sparkfitness.fit:8001/",
  // lockerbaseURL: "http://192.168.1.43:8001/",
  // jarapiUrl: "http://192.168.1.65:8000/spark/",
  // jarapiUrl: "http://api.zymmechanix.com:8112/zym/",
  jarapiUrl: "https://api.hyroxfitnessclub.com/api/V1/{endPoint}/",
 

  // lockerbaseURL: "https://device.petalyellow.com:8001/",

  firebaseConfig: {
    apiKey: "AIzaSyBTUSNInN-P9YNa0IYyEwwwiqdoqhLU3HE",
    authDomain: "spark-c00a5.firebaseapp.com",
    databaseURL: "https://spark-c00a5-default-rtdb.firebaseio.com",
    projectId: "spark-c00a5",
    storageBucket: "spark-c00a5.appspot.com",
    messagingSenderId: "799084093938",
    appId: "1:799084093938:web:86fc2e1bb52ce448964b6b",
    measurementId: "G-JWT79GXJZ6",
  },

  mqttConfig: {
    hostname: "sparkfitness.fit",
    port: 8084,
    path: "/mqtt",
    protocol: "wss",
    username : "SparkFrontUser"  ,
    password : "SparKPetalFront@Mq"
  }
};
